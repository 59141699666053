<template>
  <div id="inputsAPI">
    <!-- API KEY -->
    <CardInput
      v-model="apiKey.value"
      :hide="!apiKey.value"
      title="API key"
      placeholder="Type URL here"
      :loading="apiKey.loading"
      :disabled="true"
    >
      <template #header-actions>
        <b-button @click="updateKey" variant="link">Update Key</b-button>
      </template>
      <template #on-hide>
        <b-button @click="updateKey" variant="gradient-primary" block>
          Activate
        </b-button>
      </template>
    </CardInput>
    <!-- LINKED IP -->
    <CardInput
      :value="linkedIP.value == '0' ? 'Protection is Disabled' : linkedIP.value"
      @input="(v) => linkedIP.value == v"
      title="Linked IP"
      placeholder="Waiting for connection..."
      :disabled="true"
      :loading="ip.loading"
    >
      <template #title>
        <feather-icon
          v-b-tooltip.hover.bottom
          :title="ip.disabled ? 'IP is Disabled' : 'IP is Enabled'"
          :class="`ip-${!ip.disabled} mr-1`"
          icon="ShieldIcon"
          size="20"
        />
      </template>
      <template #header-actions>
        <b-button
          @click="updateIP(ip.disabled ? 'enable' : 'disable')"
          variant="link"
        >
          {{ !ip.disabled ? "Disable" : "Enable" }}
        </b-button>
        <b-button @click="updateIP('reset')" variant="link">
          Reset IP
        </b-button>
      </template>
    </CardInput>
  </div>
</template>

<script>
import { BButton, BBadge, VBTooltip } from "bootstrap-vue";
import CardInput from "@/components/UI/CardInput.vue";
import { mapState } from "vuex";
import ApiManage from "@/services/api-manage.service.js";
import { mapMutations } from "vuex";
export default {
  data: () => ({
    apiKey: { value: null, loading: false },
    linkedIP: { value: null, loading: false },
  }),
  watch: {
    "apiKey.value"(v) {
      this.UPDATE_API_KEY(v);
    },
  },
  methods: {
    ...mapMutations(["UPDATE_API_KEY"]),
    async updateKey() {
      this.apiKey.loading = true;
      this.apiKey.value = await ApiManage.updateKey();
      this.apiKey.loading = false;
    },
    async updateIP(status) {
      this.linkedIP.loading = true;
      const { ip } = await ApiManage.updateIP(status);
      this.linkedIP.value = ip;
      this.linkedIP.loading = false;
    },
  },
  mounted() {
    this.apiKey.value = this.user.user.api_key;
    this.linkedIP.value = this.user.user.user_ip;
  },
  computed: {
    ...mapState(["user"]),
    ip() {
      return { ...this.linkedIP, disabled: this.linkedIP.value == "0" };
    },
  },
  directives: { "b-tooltip": VBTooltip },
  components: { CardInput, BButton, BBadge },
};
</script>

<style lang="scss" scoped>
#inputsAPI {
  display: flex;
  justify-content: space-around;
  align-items: center;
  > * {
    width: 100%;
    flex: 1;
    &:first-child {
      margin-right: 28px;
    }
  }
  .ip-false {
    color: #f44336;
  }
  .ip-true {
    color: #4caf50;
  }
  @media (max-width: 780px) {
    flex-direction: column;
    > * {
      margin: 0 !important;
      margin-bottom: 15px !important;
    }
  }
}
</style>