<template>
  <!-- Documentation -->
  <div class="api-doc">
   
    <h3><center>PHP instant API Documentation</center></h3>
    <h4 class="mt-2"> </h4>
    
    
<div class="info-display">
  <b>  <p>PHP LINK FOR INSTANT REPLY: &nbsp;</p>
<p><a href="https://alpha.imeicheck.com/api/php-api/create?key=API_KEY&service=SERVICE_ID&imei=IMEI/SN" target="_blank">https://alpha.imeicheck.com/api/php-api/create?key=API_KEY&amp;service=SERVICE_ID&amp;imei=IMEI/SN</a></p> </b>
<p><br></p>
<p>&nbsp;-Replace &quot;API_KEY&quot; with your API key.</p>
<p>&nbsp;-Replace &quot;SERVICE_ID&quot; with the service id you find in PHP LIST. (For example for Find my iPhone - id is 1.)</p>
<p>&nbsp;-Replace &quot;IMEI / SN&quot; with your IMEI or Serial.</p>
    
<p><br></p>
<p>For the value &quot;status&quot; can be:</p>
<p>-&gt; &quot;success&quot; - the order status is successful.</p>
<p>-&gt; &quot;failed&quot; - the status of the order is fail / rejected</p>
<p>-&gt; &quot;error&quot; - used for system validation errors, such as: &quot; Invalid ApiKey &quot;  &nbsp;/&nbsp;  &quot; Wrong IP &quot;  &nbsp;or&nbsp;  &quot; Credit Error &quot;. These answers are preferably not displayed to clients.</p>

</div>
<br>
<div class="info-display">
<b>  <p>PHP LINK FOR ORDER ID HISTORY: &nbsp;</p>
<p><a href="https://alpha.imeicheck.com/api/php-api/history?key=API_KEY&orderId=612712" target="_blank">https://alpha.imeicheck.com/api/php-api/history?key=API_KEY&amp;orderId=ORDER_ID</a></p> </b>
<p><br></p>
<p>&nbsp;-Replace &quot;API_KEY&quot; with your API key.</p>
<p>&nbsp;-Replace &quot;ORDER_ID&quot; with order id.</p>
</div>
<br>
<div class="info-display">
<b>  <p>PHP LINK FOR ACCOUNT BALANCE: &nbsp;</p>
<p><a href="https://alpha.imeicheck.com/api/php-api/balance?key=API_KEY" target="_blank">https://alpha.imeicheck.com/api/php-api/balance?key=API_KEY</a></p> </b>
<p><br></p>
<p>&nbsp;-Replace &quot;API_KEY&quot; with your API key.</p>
Note: If there are orders in process, the credit will be deducted from the user balance (and that credit will be returned in case the order is rejected).
</div>

<br><br>
<div class="info-display">
     Notes:<br><br><b>
      - HTTP GET/POST all supported for all PHP links! <br><br>
      - The "LIMITS" field in the PHP services table represents the maximum recommended parallel processing limit for each service! </b><br>
  (Each service can process a limited number of orders in parallel. If you send too many orders at the same time, the firewall may detect as doss attack or reject it. That is why it is recommended that you send them when bulk orders in batch.)<br><br> <b>
      - By default we have activated a system that protects duplicate orders for 10 seconds.</b><br>
      (So if you sent an order and it was successful and / or still in the process). For 10 seconds, the same IMEI / SN will be rejected to prevent money loss.)
    </div>


   
   
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">

.info-display {
  margin-right: 42px;
  padding: 20px;
  box-shadow: 1px 1px 20px #171d31a8;
  border-radius: 20px;
  flex: 0.4;
  @media (max-width: 740px) {
    margin-right: 0;
  }
}

</style>
