<template>
  <section class="copyText">
    <p @click="copy" class="text-copy">
      <span>{{ text }}</span>
      <span class="text-success" v-if="copied">
        <feather-icon icon="CopyIcon" />
      </span>
    </p>
  </section>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: {
    text: {
      required: true,
    },
  },
  data: () => ({
    copied: false,
  }),
  methods: {
    copy() {
      const txt = this.text;
      this.$copyText(txt).then(
        () => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "COPIED",
                icon: "CopyIcon",
                variant: "success",
              },
            },
            { position: "bottom-center" }
          );
          this.copied = true;
        },
        () => (this.copied = false)
      );
    },
  },
  components: {
    ToastificationContent,
  },
};
</script>

<style lang="scss" scoped>
.text-copy {
  text-align: center;
  margin: 0;
  padding: 5px 20px;
  border-radius: 15px;
  background: #e4e4e4;
  border: 2px dashed #000000;
  color: black;
  transition: all 0.3s ease-out;
  &:hover {
    cursor: pointer;
    border: 2px dashed #1dcb22;
  }
}
.dark-layout {
  .text-copy {
    background: #0000004d;
    color: white;
    &:hover {
      border: 2px dashed #00ff0a;
    }
  }
}
</style>