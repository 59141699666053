<template>
  <b-card class="card-topheader mt-4">
    <template #header>
      <div class="header bg-primary" :class="headerClass">
        <div class="header-tab" :class="padding && 'padding-title'">
          <slot name="topheader" />
          <div class="back-btn" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
            <feather-icon
              v-if="back"
              class="back-btn"
              size="22"
              icon="ArrowLeftIcon"
              @click="$emit('back')"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="mt-5">
      <slot />
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  props: ["back", "padding", "headerClass"],
  components: { BCard },
  directives: { Ripple },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
.padding-title {
  padding: 8px 0;
}
.card-topheader {
  .header-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > .card-header {
    color: white;
    margin: -47px 10px;
    padding: 10px 5px;
    button {
      color: white !important;
      border-radius: 0 !important;
    }
    button.active {
      border-bottom: 2px solid #fff;
    }
    .header {
      width: 100%;
      border-radius: 10px !important;
      padding: 8px 15px;
    }
  }
}
.back-btn {
  cursor: pointer;
  border-radius: 8px;
}
</style>