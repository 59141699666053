<template>
  <section id="dhruAPI">
    <!-- All info -->
    <div class="info-display">
      <div
        class="info-box"
        v-for="(inform, i) in Object.values(info)"
        :key="`in${i}`"
      >
        <div v-if="inform.value">
          <h5 class="mr-1 mb-1" v-text="`${inform.label}`" />
          <CopyText :text="inform.value" />
        </div>
      </div>
    </div>
    <!-- Documentation -->
    <div class="dhru-doc">
           <h1><center>INFO</center></h1>
      <h3 class="mt-2">The script is fully compatible with DHRU BULK API(v6.1)</h3> <br>
      <p>
      <strong>   We recommend that you enable DHRU BULK API SEND + GET in your script to improve performance and response time.</strong><br> <br>
      ->   Set the "Send Order Per Batch" limit to 120 - to avoid duplicate orders.<br>
      </p>
      <p>
      ->  If you use cronjob in seconds, set the "Send Order Per Batch" number equal to the number of seconds (at which you run cronjob).
      </p>
    </div>
  </section>
</template>

<script>
import CopyText from "@/components/util/CopyText.vue";
import { mapState } from "vuex";
export default {
  data: () => ({
    info: {
      apiKey: { label: "Your Access key", value: "12345" },
      username: { label: "Username", value: "12345" },
      url: { label: "URL", value: "https://alpha.imeicheck.com" },
    },
  }),
  watch: {
    "user.api_key"() {
      this.updateUser();
    },
  },
  computed: {
    ...mapState({ getUser: "user" }),
    user() {
      return this.getUser.user;
    },
  },
  mounted() {
    this.updateUser();
  },
  methods: {
    updateUser() {
      this.info.apiKey.value = this.user.api_key;
      this.info.username.value = this.user.username;
    },
  },
  components: { CopyText },
};
</script>

<style lang="scss" scoped>
#dhruAPI {
  display: flex;
  @media (max-width: 740px) {
    flex-direction: column;
    > *:first-child {
      margin-bottom: 20px;
    }
  }
}
.info-box {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.info-display {
  margin-right: 42px;
  padding: 20px;
  box-shadow: 1px 1px 20px #171d31a8;
  border-radius: 20px;
  flex: 0.4;
  @media (max-width: 740px) {
    margin-right: 0;
  }
}
.dhru-doc {
  flex: 0.6;
}
</style>
