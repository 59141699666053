<template>
  <CardHeader>
    <template v-slot:topheader>
      <div>
        <b-button
          v-for="(switchPage, i) in pages"
          :key="`sp${i}`"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :pressed="switchPage.name == activePage"
          variant="flat-primary"
          @click="activePage = switchPage.name"
          pill
        >
          {{ switchPage.label }}
        </b-button>
      </div>
    </template>
    <!-- All pages -->
    <component :is="pages.find((p) => p.name == activePage).component" />
  </CardHeader>
</template>

<script>
import CardHeader from "@/components/UI/CardHeader.vue";
import { BCard, BTabs, BButton, BTab } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import DhruApi from "./DhruApi.vue";
import PhpDoc from "./PhpDoc.vue";
import PhpList from "./PhpList.vue";
export default {
  data: () => ({
    pages: [
      { name: "dhru", label: "DHRU", component: "dhru-api" },
      { name: "php-doc", label: "PHP DOC", component: "php-doc" },
      { name: "php-list", label: "PHP LIST", component: "php-list" },
    ],
    activePage: "dhru", // dhru, php-doc, php-list
  }),
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    CardHeader,
    "dhru-api": DhruApi,
    "php-doc": PhpDoc,
    "php-list": PhpList,
  },
};
</script>

