<template>
  <b-table small :fields="fields" :items="valuesList" responsive="sm">
    <template #cell(is_object)="data">
      <b-badge pill :variant="data.value ? 'success' : 'danger'">
        {{ data.value ? "yes" : "no" }}
      </b-badge>
    </template>
  </b-table>
</template>

<script>
import { BTabs, BTab, BCard, BTable, BBadge } from "bootstrap-vue";
import ServiceApi from "@/services/services.service.js";
export default {
  data: () => ({
    fields: [
      "id",
      { key: "name", label: "Name" },
      { key: "price", label: "Price" },
      { key: "is_object", label: "Object" },
      { key: "curl_max", label: "Limits" },
    ],
    valuesList: [],
  }),
  async mounted() {
    this.valuesList = (await ServiceApi.phpServiceList()).data;
    console.log(this.valuesList);
  },
  components: { BTabs, BTab, BCard, BTable, BBadge },
};
</script>

<style lang="scss">
</style>