import api from '@axios';

class ApiManage {
	// Update API KEY => new key
	static async updateKey() {
		const { data } = await api.put(`/api-manage/update-apikey`);
		return data;
	}
	// Update IP status ['disable', 'enable', 'reset']
	static async updateIP(status) {
		const { data } = await api.put(`/api-manage/update-ip/${status}`);
		return data;
	}
}

export default ApiManage;
