<template>
  <b-card>
    <div class="header-input">
      <b-card-title class="card-input-title mb-0">
        <slot name="title" />
        {{ title }}
      </b-card-title>
      <div v-if="!hide && !loading">
        <slot name="header-actions" />
      </div>
    </div>
    <b-skeleton v-if="loading" width="100%" height="35px"></b-skeleton>
    <b-form-input
      v-else-if="!hide"
      :value="value"
      @input="(v) => $emit('input', v)"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <div class="text-center" v-else>
      <slot name="on-hide" />
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BFormInput, BSkeleton } from "bootstrap-vue";
export default {
  props: ["value", "title", "placeholder", "disabled", "hide", "loading"],
  components: { BCard, BCardTitle, BFormInput, BSkeleton },
};
</script>

<style lang="scss" scoped>
.card-input-title {
  display: flex;
  align-items: center;
}
.header-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
</style>