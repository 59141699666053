<template>
  <section id="apiManage">
    <!-- API Keys Inputs -->
    <Inputs />
    <!-- Content API -->
    <Content />
  </section>
</template>

<script>
import Inputs from "./Inputs.vue";
import Content from "./content/Index.vue";
export default {
  components: { Inputs, Content },
};
</script>

<style lang="scss" scoped>
#apiManage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>